import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

const accordionData = [
  {
    title: "Fullstack Developer — 100k-120k CAD · 0.1% – 0.3% Equity",
    roles: [
      {
        heading: "Fullstack Developer — Rivr",
        subPoints: [
          "Remote within Canada, Fulltime",
          "100k-120k CAD · 0.1% – 0.3% Equity",
        ],
      },
      {
        heading: "Botni.Vision",
        paragraphs: [
          "Botni.Vision is a Montreal-based startup with a distributed team working in the media processing and video analysis space. Our first product, Rivr, enables users to search within video as easily as they search for video. Rivr turns any video into searchable experiences, making it easy to find your best moments, learn from your community, and share content like never before.",
          "Currently, we work with creators, streamers, influencers, gamers, and enterprises, creating new technology to produce better content faster. We make the impossible possible by removing the bottlenecks of running large-scale video-first advertising campaigns while also providing an accessible, fun, and engaging tool for end users.",
          "Our founders are developers and focused on technology—every day we’re working on fun, challenging, and first-to-market technology and services that already have significant buy-in from industry leaders.",
        ],
      },
      {
        heading: "The Role",
        bulletPoints: [
          "You will be the principal developer for all things customer-facing. Your knowledge and experience will be paramount in leading the decision making process around how our current and future web applications are architected, developed, scaled, and supported.",
          "You will build interactive interfaces, connect them with real-time and at-rest data sources, and effectively leverage best practices in user experience, error handling, feedback, and performance optimization.",
          "You have a knack for turning complex technology and concepts into accessible, fun, and engaging experiences.",
          "We move fast. Your ability to maintain focus, work on what is needed, and be the thought-leader in your domain is essential to our success.",
        ],
      },
      {
        heading: "Qualifications",
        bulletPoints: [
          "5+ years of experience in web development using modern technologies and languages",
          "Experience with React, Angular, or similar frameworks",
          "Experience with APIs, GraphQL, WebSockets, and WebRTC",
          "Experience with OAuth and third-party integrations (social platforms, payment gateways)",
          "Experience with databases such as PostgreSQL, and database schemas",
          "Experience with Canvas and complex interactive components",
          "Experience with big data or media streaming applications",
          "Knowledge of or experience with graphing libraries such as Plotly, D3, and Dash",
          "Knowledge of or experience with in-browser image, audio, and video manipulation tools",
          "Knowledge of or experience with Kubernetes, Docker, and Event-Driven Applications",
        ],
      },
      {
        heading: "Nice-to-haves",
        bulletPoints: [
          "Experience with Python",
          "Experience with FFMPEG, PyAV, or FFMPEG WebAssembly",
          "Knowledge of or experience with product gamification or game development",
          "Passionate about gaming, streaming, or content creation",
        ],
      },
    ],
    buttonHref: "mailto:careers@rivr.stream",
  },
  {
    title: "Marketing/Enterprise Campaign Intern — $21/h CAD",
    roles: [
      {
        heading: "Marketing/Enterprise Campaign Intern — Rivr",
        subPoints: [
          "Montreal, QC –  Remote (80%) and on-site (20%)",
          "September 2nd to December 2nd, 2024 (flexible based on academic schedule)",
          "$21 CAD/Hour",
        ],
      },
      {
        heading: "Botni.Vision",
        paragraphs: [
          "Botni.Vision is a Montreal-based startup with a distributed team working in the media processing and video analysis space. Our first product, Rivr, enables users to search within video as easily as they search for video. Rivr turns any video into searchable experiences, making it easy to find your best moments, learn from your community, and share content like never before.",
          "Currently, we work with creators, streamers, influencers, gamers, and enterprises, creating new technology to produce better content faster. We make the impossible possible by removing the bottlenecks of running large-scale video-first advertising campaigns while also providing an accessible, fun, and engaging tool for end users.",
          "Our founders are developers and focused on technology—every day we’re working on fun, challenging, and first-to-market technology and services that already have significant buy-in from industry leaders.",
        ],
      },
      {
        heading: "The Role",
        paragraphs: [
          "Botni.Vision is excited to announce an opportunity for a highly motivated and creative Marketing/Campaign Manager Intern to join our dynamic team for the upcoming fall. We make Rivr, a revolutionary in-media search and content production platform for creators and brands. This internship offers a unique chance to gain hands-on experience in the fast-paced world of technology marketing and client campaign management.",
        ],
      },
      {
        heading: "Responsibilities",
        bulletPoints: [
          "Collaborate with the marketing team to develop and execute creative and effective campaigns",
          "Assist in the planning, coordination, and execution of enterprise activations ",
          "Monitor and report on the performance of campaigns, providing insights and recommendations for improvement",
          "Work closely with the enterprise campaign management team",
          "Provide additional support with day-to-day marketing tasks and administrative activities",
        ],
      },
      {
        heading: "Qualifications",
        bulletPoints: [
          "Currently enrolled in a marketing, communications, or business program",
          "Strong interest in marketing, campaign management, and brand development within the technology space",
          "Excellent written and verbal communication skills",
          "Creative thinker with the ability to generate innovative ideas",
          "Detail-oriented and able to manage multiple tasks simultaneously",
          "Team player with a positive attitude and eagerness to learn",
          "Assets include English/French bilingualism, gaming/creator industry knowledge, and skills in graphic design and social media management",
        ],
      },
      {
        heading: "Benefits",
        bulletPoints: [
          "Gain valuable hands-on experience in marketing and campaign management",
          "Work in a collaborative and supportive team environment",
          "Network with industry professionals",
          "Collaborate on real-world projects and challenges",
          "Flexible working hours to accommodate academic commitments",
        ],
      },
      {
        heading: "How to Apply",
        paragraphs: [
          'Interested candidates are invited to submit their resume, a cover letter via email. Please include "Marketing/Enterprise Campaign Intern Application" in the subject line.',
          "The deadline for applications is August 15th, 2024. Only shortlisted candidates will be contacted for interviews.",
        ],
      },
    ],
    buttonHref:
      "mailto:nolwenn@botni.vision?subject=Marketing/Enterprise Campaign Intern Application",
  },
];

const Careers = () => {
  return (
    <Box w={"100%"} height={"100%"} position={"relative"} bg={"rivr.black"}>
      <Center pt={{ xl: 48, base: 32 }} pb={{ xl: 32, base: 24 }}>
        <VStack
          alignItems={"center"}
          w={"100%"}
          px={6}
          maxW={"1400px"}
          spacing={6}
        >
          <Heading as="h1" fontSize={"5xl"} textAlign={"center"}>
            Transparency, diversity, equity
          </Heading>
          <Text textAlign={"center"} color={"gray.300"} pb={24}>
            We’re a small team that leans on each other’s strengths and
            experiences every day. We’re building something new, which is
            exciting, and first-to-market, which can be challenging. We’re
            opinionated and believe that creating good products relies on mutual
            respect, a diverse team, and facing those challenges together. We
            believe in transparency and proactively working to make the
            technology industry more fair and equitable through progressive
            policy.
          </Text>
          <Heading as="h2" fontSize={"3xl"}>
            Join the team
          </Heading>
          <VStack
            alignItems={"center"}
            w={"100%"}
            bg={"whiteAlpha.100"}
            pt={6}
            pb={3}
            px={6}
            borderRadius={"xl"}
            spacing={6}
            borderWidth={1}
          >
            <Accordion allowToggle w={"100%"}>
              {accordionData.map((item, index) => (
                <AccordionItem
                  key={index}
                  bg="whiteAlpha.100"
                  borderRadius={"lg"}
                  borderWidth={1}
                  p={2}
                  mb={3}
                >
                  <AccordionButton
                    borderRadius={"lg"}
                    _hover={{ bg: "transparent" }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {item.title}
                    </Box>
                    <AccordionIcon boxSize={8} />
                  </AccordionButton>
                  <AccordionPanel pb={4} color={"gray.300"}>
                    {item.roles.map((role, roleIndex) => (
                      <VStack
                        key={roleIndex}
                        alignItems={"flex-start"}
                        spacing={0}
                        pt={6}
                      >
                        <Text
                          fontSize={"xl"}
                          fontWeight={"semibold"}
                          color={"gray.50"}
                          pb={3}
                        >
                          {role.heading}
                        </Text>
                        {role.subPoints && role.subPoints.length > 0 && (
                          <Box pb={2}>
                            {role.subPoints.map((point, pointIndex) => (
                              <Text key={pointIndex} fontSize={"lg"}>
                                {point}
                              </Text>
                            ))}
                          </Box>
                        )}
                        {role.paragraphs &&
                          role.paragraphs.length > 0 &&
                          role.paragraphs.map((para, paraIndex) => (
                            <Text key={paraIndex} pb={4}>
                              {para}
                            </Text>
                          ))}
                        {role.bulletPoints && role.bulletPoints.length > 0 && (
                          <UnorderedList pl={12} spacing={1} pb={4}>
                            {role.bulletPoints.map((bullet, bulletIndex) => (
                              <ListItem key={bulletIndex}>{bullet}</ListItem>
                            ))}
                          </UnorderedList>
                        )}
                      </VStack>
                    ))}
                    <Button
                      colorScheme="red"
                      bg="rivr.red"
                      color="white"
                      alignSelf={"center"}
                      w="100%"
                      as="a"
                      href={item.buttonHref}
                      size="lg"
                      mt={10}
                    >
                      Apply via email
                    </Button>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </VStack>
        </VStack>
      </Center>
    </Box>
  );
};

export default Careers;
