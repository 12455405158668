import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const BetaRequest = () => {
  return (
    <VStack
      w={"100%"}
      h={"100dvh"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={"rivr.black"}
      p={6}
    >
      <VStack spacing={4} h={"auto"} textAlign={"center"} maxW={"700px"}>
        <Text
          textTransform={"uppercase"}
          fontWeight={"bold"}
          color={"rivr.red"}
          fontSize={"xl"}
        >
          Closed beta has ended
        </Text>
        <Heading>Rivr is now in early access!</Heading>
        <Text color={"gray.300"} sx={{ textWrap: "pretty" }}>
          Thank you to everyone who participated in Rivr's closed beta. Your
          feedback and support have helped us reach this exciting milestone. If
          you'd like to join early access, apply below.
        </Text>
        <VStack pt={4}>
          <Text fontSize={"lg"}>What is early access?</Text>
          <Text color={"gray.300"}>
            Rivr already offers everything you need to discover your best
            Moments, understand your audience, and create standout content. But
            there’s more we’re working on.
          </Text>
          <Text color={"gray.300"}>
            Early access helps us manage the influx of new users while giving
            even more creators the chance to try Rivr.
          </Text>
        </VStack>
      </VStack>
      <VStack spacing={4} pt={10}>
        <Button
          rightIcon={<ChevronRightIcon />}
          colorScheme={"green"}
          as={"a"}
          href={"/early-access"}
        >
          Apply for early access
        </Button>
        <Button
          leftIcon={<ChevronLeftIcon />}
          colorScheme={"red"}
          variant={"outline"}
          size={"sm"}
          as={"a"}
          href={"/"}
        >
          Back to home
        </Button>
      </VStack>
    </VStack>
  );
};

export default BetaRequest;
