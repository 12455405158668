import { useRef, useEffect } from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Box,
  Flex,
  Heading,
  Image,
  HStack,
  VStack,
  AspectRatio,
  useBreakpointValue,
} from "@chakra-ui/react";
import TextRotator from "../TextRotator/TextRotator";
import {
  Bilibili,
  Dailymotion,
  Facebook,
  Kick,
  Niconico,
  QQ,
  Rumble,
  Steam,
  Tiktok,
  Trovo,
  Twitch,
  Vimeo,
  YouTube,
} from "../../assets/platforms";
import videoSearchVideo from "../../assets/video/rivr-video-search.webm";
import videoSearchVideoMp4 from "../../assets/video/rivr-video-search.mp4";
import searchVideoThumbnail from "../../assets/video/search-video-thumbnail.webp";

const marquee = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const marqueeReverse = keyframes`
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 3;
    border-radius: 12px;
    box-shadow: 0px 0px 32px 32px #0c0d14cc;
  }
`;

const LogosLine = () => (
  <>
    <Image src={Bilibili} alt="Bilibili logo" />
    <Image src={Dailymotion} alt="Dailymotion logo" />
    <Image src={Facebook} alt="Facebook logo" />
    <Image src={Kick} alt="Kick logo" />
    <Image src={Niconico} alt="Niconico logo" />
    <Image src={QQ} alt="QQ logo" />
    <Image src={Rumble} alt="Rumble logo" />
    <Image src={Steam} alt="Steam logo" />
    <Image src={Tiktok} alt="Tiktok logo" />
    <Image src={Trovo} alt="Trovo logo" />
    <Image src={Twitch} alt="Twitch logo" />
    <Image src={Vimeo} alt="Vimeo logo" />
    <Image src={YouTube} alt="YouTube logo" />
  </>
);

const PlatformLogos = ({ animation }) => (
  <Box
    className="logoline-wrapper"
    css={css`
      animation: ${animation} 120s linear infinite;
      display: flex;
    `}
    w={"200vw"}
    height={37}
    overflow={"visible"}
  >
    <HStack alignItems="center">
      <LogosLine />
      <LogosLine />
      <LogosLine />
      <LogosLine />
      <LogosLine />
      <LogosLine />
      <LogosLine />
    </HStack>
  </Box>
);

const VideoSearch = () => {
  const imgRef = useRef(null);
  const behindRef = useRef(null);

  const updateHeight = () => {
    if (imgRef.current && behindRef.current) {
      const height = imgRef.current.offsetHeight;
      behindRef.current.style.height = `${height}px`;
    }
  };

  useEffect(() => {
    updateHeight(); // Run once at mount to set initial height
    window.addEventListener("resize", updateHeight); // Run whenever the window is resized

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <Box bg={"rivr.black"}>
      <Box position="relative" w="100%" overflow="hidden">
        <VStack
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          px={[6, 8, 12]}
          spacing={6}
          h="100%"
          justifyContent="center"
          flexDirection="column"
          mx="auto"
        >
          <VStack justifyContent="center">
            <div
              id="smart-search"
              style={{
                position: "relative",
                top: "-104px",
                visibility: "hidden",
              }}
            ></div>
            <Heading
              as="h2"
              size="lg"
              color="gray.50"
              textAlign="center"
              zIndex={1}
            >
              Search any video for
            </Heading>
            <TextRotator
              messages={[
                "your best Moments",
                "community insights",
                "brand mentions",
                "Hype clips",
                "chat reactions",
                "call-outs and keywords",
              ]}
              interval={3000}
            />
          </VStack>
          <Box position="relative" width="100%" height={"100%"}>
            <Box
              padding="2"
              bg="#181924"
              ref={imgRef}
              zIndex={3}
              borderRadius={16}
              position={"inherit"}
              boxShadow="0 -5px 30px -3px #0C0D14, 0 5px 30px -3px #0C0D14"
            >
              <AspectRatio ratio={16 / 7.5}>
                <Video autoPlay muted loop poster={searchVideoThumbnail}>
                  <source src={videoSearchVideo} type="video/webm" />
                  <source src={videoSearchVideoMp4} type="video/mp4" />
                </Video>
              </AspectRatio>
            </Box>
            <Flex
              ref={behindRef}
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              alignSelf="center"
              overflow="visible"
              zIndex={1}
            >
              <VStack spacing={4} w="100%" className="video-search-behind">
                <PlatformLogos animation={marquee} />
                <PlatformLogos animation={marqueeReverse} />
                <Box
                  css={css`
                    transform: translateX(-31%);
                  `}
                >
                  <PlatformLogos animation={marquee} />
                </Box>
              </VStack>
            </Flex>
            <Heading
              as="h2"
              size={{ xl: "md", lg: "md", md: "md", sm: "sm" }}
              sx={{ textWrap: "balance" }}
              color="gray.300"
              fontWeight={"medium"}
              mt={6}
              textAlign="center"
              lineHeight="1.5"
              zIndex={1}
            >
              Play a game, host a podcast, lead a workshop, or just chat with
              your community.
              <br />
              Rivr works on any type of content.
            </Heading>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

export default VideoSearch;
