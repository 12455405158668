import { useState } from "react";
import {
  Box,
  Text,
  VStack,
  Heading,
  Button,
  Image,
  HStack,
  List,
  ListItem,
  ListIcon,
  Tooltip,
} from "@chakra-ui/react";
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import RivrBot from "../assets/rivr-bot.png";

const EarlyAccess = () => {
  const [hover, setHover] = useState(false);

  return (
    <VStack
      w={"100%"}
      h={{ xl: "100dvh", sm: "100%", base: "100%" }}
      position={"relative"}
      bg={"rivr.black"}
      justifyContent={{ xl: "center", sm: "flex-start", base: "flex-start" }}
      alignItems={"center"}
      px={6}
      pt={{ xl: 48, md: 40, sm: 32, base: 28 }}
      pb={{ xl: 24, md: 16, sm: 12, base: 8 }}
      spacing={6}
    >
      <HStack
        spacing={{ xl: 12, md: 6, sm: 0, base: 0 }}
        flexDir={{ xl: "row", sm: "column", base: "column" }}
        h={{ xl: "max-content", sm: "100%", base: "100%" }}
      >
        <VStack
          w={{ xl: "50%", sm: "100%", base: "100%" }}
          maxW={{ xl: "460px", md: "75%", sm: "100%", base: "100%" }}
          alignItems={"flex-start"}
          justifyContent={{ xl: "center", sm: "flex-end" }}
          spacing={4}
          pb={{ xl: 0, sm: 12, base: 12 }}
          h={{ xl: "max-content", sm: "100%", base: "100%" }}
        >
          <Heading
            as={"h1"}
            fontSize={{ base: "3xl", md: "4xl" }}
            textAlign={"left"}
            sx={{ textWrap: "balance" }}
            pb={4}
          >
            You're on your way to joining early access!
          </Heading>

          <Text
            color={"gray.300"}
            fontSize="lg"
            fontWeight="semibold"
            sx={{ textWrap: "balance" }}
          >
            Rivr’s advanced creator tools make it easy to:
          </Text>
          <List spacing={3} pb={4}>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color={"green.300"} mb={0.5} />
              Quickly discover the best Moments in your videos.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color={"green.300"} mb={0.5} />
              Reformat, download, and share knockout content.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color={"green.300"} mb={0.5} />
              Learn what your community finds most engaging.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color={"green.300"} mb={0.5} />
              Streamline your workflow and spend less time scrubbing.
            </ListItem>
          </List>
          <Tooltip
            label="Thanks for being here!"
            placement={"right"}
            hasArrow
            ml={1}
          >
            <Image
              src={RivrBot}
              w={8}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={{
                transform: hover ? "translateY(-2px)" : "translateY(0)",
                transition: "transform 0.3s ease",
              }}
            />
          </Tooltip>
        </VStack>
        <VStack
          w={{ xl: "50%", sm: "100%" }}
          maxW={{ xl: "460px", sm: "100%" }}
          h={"100%"}
          justifyContent={"center"}
        >
          <VStack
            h={"fit-content"}
            bg={"whiteAlpha.200"}
            borderWidth={1}
            borderRadius={"lg"}
            p={8}
            spacing={6}
          >
            <Text fontSize={"md"} textAlign={"left"} fontWeight={"medium"}>
              Follow these steps to float to the top of the waitlist:
            </Text>
            <VStack alignItems={"flex-start"} mb={6} w="100%">
              <HStack w="100%">
                <Text textAlign={"left"}>1.</Text>
                <Button
                  rightIcon={<ExternalLinkIcon />}
                  w="100%"
                  colorScheme="green"
                  as="a"
                  href="https://app.rivr.stream"
                  target="_blank"
                >
                  Create an account
                </Button>
              </HStack>
              <Text fontSize={"sm"} color="gray.300" pl={5}>
                Let us know you want in. 👋
              </Text>
            </VStack>
            <VStack alignItems={"flex-start"} mb={6} w="100%">
              <HStack w={"100%"}>
                <Text textAlign={"left"}>2.</Text>
                <Button
                  rightIcon={<ExternalLinkIcon />}
                  w="100%"
                  colorScheme="blue"
                  as="a"
                  href="https://forms.gle/unCVy3eu57RXF3i16"
                  target="_blank"
                >
                  Complete the survey
                </Button>
              </HStack>
              <Text fontSize={"sm"} color="gray.300" pl={5}>
                Answer a few questions about yourself. 😎
              </Text>
            </VStack>
            <VStack alignItems={"flex-start"} w="100%">
              <HStack w="100%">
                <Text textAlign={"left"}>3.</Text>
                <Button
                  rightIcon={<ExternalLinkIcon />}
                  w="100%"
                  colorScheme="purple"
                  as="a"
                  href="https://discord.gg/WTkWJKzyQK"
                  target="_blank"
                >
                  Join the Discord
                </Button>
              </HStack>
              <Text fontSize={"sm"} color="gray.300" pl={5}>
                This is our main channel for collecting feedback and providing
                support. We’ll notice you popped in. 👀
              </Text>
            </VStack>
          </VStack>
        </VStack>
      </HStack>
      <Box pb={6}>
        <Button
          leftIcon={<ChevronLeftIcon />}
          mt={4}
          colorScheme="red"
          variant={"outline"}
          size="sm"
          as="a"
          href="/"
        >
          Back to home
        </Button>
      </Box>
    </VStack>
  );
};

export default EarlyAccess;
