import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  VStack,
  Text,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Calendar, Send } from "@icon-park/react";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://getform.io/f/db710640-6748-4073-a32e-1197702bf535",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        console.log("Form submitted successfully!");
        setFormSubmitted(true);
        setSubmitError(null);
        reset();
      } else {
        throw new Error(`Error submitting form: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormSubmitted(false);
      setSubmitError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = () => {
    const name = document.getElementById("name").value.trim();
    const company = document.getElementById("company").value.trim();
    const email = document.getElementById("email").value.trim();
    const message = document.getElementById("message").value.trim();

    if (name && company && email && message) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  };

  return (
    <>
      <div
        id="contact"
        style={{
          position: "relative",
          top: "-120px",
          visibility: "hidden",
        }}
      ></div>
      <Box>
        <Box width="100%" maxWidth="800px" mx={"auto"}>
          <HStack>
            <Heading as="h2" size="lg" color="gray.50" mb={4}>
              Get in touch
            </Heading>
          </HStack>

          {formSubmitted && (
            <Text
              color="green.300"
              textAlign="center"
              mb={4}
              bg={"gray.900"}
              p={2}
              borderRadius={6}
            >
              Thank you! Your form has been submitted successfully.
            </Text>
          )}

          {submitError && (
            <Text
              color="red.300"
              textAlign="center"
              mb={4}
              bg={"gray.900"}
              p={2}
              borderRadius={6}
            >
              {submitError}
            </Text>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4}>
              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">Name *</FormLabel>
                <Input
                  variant={"filled"}
                  id="name"
                  {...register("name", { required: "Name is required" })}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.company}>
                <FormLabel htmlFor="company">Company *</FormLabel>
                <Input
                  variant={"filled"}
                  id="company"
                  {...register("company", { required: "Company is required" })}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.company?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.email}>
                <FormLabel htmlFor="email">Email *</FormLabel>
                <Input
                  variant={"filled"}
                  id="email"
                  type="email"
                  {...register("email", { required: "Email is required" })}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.message}>
                <FormLabel htmlFor="message">Message *</FormLabel>
                <Textarea
                  variant={"filled"}
                  maxH={"16rem"}
                  id="message"
                  {...register("message", { required: "Message is required" })}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
              </FormControl>
              <HStack w={"100%"}>
                <Button
                  rightIcon={<Send />}
                  width={"100%"}
                  colorScheme={"blackAlpha"}
                  color={"gray.50"}
                  bg={"rivr.red"}
                  variant={"solid"}
                  isLoading={isLoading}
                  type={"submit"}
                  _hover={{ bg: "red.300" }}
                  _active={{ bg: "red.300" }}
                  aria-label={"Submit Contact Form"}
                  disabled={!isFormFilled}
                >
                  Submit
                </Button>
                <Button
                  rightIcon={<Calendar />}
                  width={"100%"}
                  colorScheme={"orange"}
                  variant={"solid"}
                  aria-label={"Book a demo"}
                  as={"a"}
                  href={"https://meetings.hubspot.com/aidan-kessell"}
                  target={"_blank"}
                >
                  Book a demo
                </Button>
              </HStack>
            </VStack>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default ContactForm;
