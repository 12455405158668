import { useState, useRef } from "react";
import {
  Box,
  VStack,
  Text,
  Image,
  AspectRatio,
  Flex,
  HStack,
  Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Calendar, Send } from "@icon-park/react";
import Analyze from "../../assets/analyze.png";
import Moments from "../../assets/moments.png";
import Mentions from "../../assets/mentions.png";
import Vertical from "../../assets/vertical.png";

const items = [
  {
    src: Moments,
    text: "Discover quality content",
    description:
      "Effortlessly find, highlight, and download the most engaging moments.",
  },
  {
    src: Analyze,
    text: "Achieve unprecedented scale",
    description: "Eliminate the bottlenecks of running large-scale campaigns.",
  },
  {
    src: Mentions,
    text: "Locate brand mentions",
    description:
      "Detect and review every mention of your brand across creators and their communities.",
  },
  {
    src: Vertical,
    text: "Increase earned media",
    description:
      "Rivr works for creators and brands, amplifying your campaign reach.",
  },
];

const B2BBenefits = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].pageX - e.currentTarget.offsetLeft;
    scrollLeft.current = e.currentTarget.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!startX.current) return;
    const x = e.touches[0].pageX - e.currentTarget.offsetLeft;
    const walk = x - startX.current;
    e.currentTarget.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseDown = (e) => {
    startX.current = e.pageX - e.currentTarget.offsetLeft;
    scrollLeft.current = e.currentTarget.scrollLeft;
    e.currentTarget.addEventListener("mousemove", handleMouseMove);
  };

  const handleMouseUp = (e) => {
    startX.current = null;
    e.currentTarget.removeEventListener("mousemove", handleMouseMove);
  };

  const handleMouseMove = (e) => {
    if (!startX.current) return;
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = x - startX.current;
    e.currentTarget.scrollLeft = scrollLeft.current - walk;
  };

  return (
    <VStack w={"100%"} pt={[16, 16, 32]} px={6}>
      <Box
        w={"100%"}
        maxWidth={"1400px"}
        overflowX={"auto"}
        whiteSpace={"nowrap"}
        cursor={"default !important"}
        py={6}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          userSelect: "none",
          touchAction: "pan-y pinch-zoom",
        }}
        display="flex"
        justifyContent={{
          base: "start",
          sm: "start",
          lg: "start",
          xl: "center",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {items.map(({ text }, index) => (
          <Text
            as={"span"}
            display={"inline-block"}
            key={index}
            onClick={() => setCurrentIndex(index)}
            cursor={"pointer"}
            color={currentIndex === index ? "gray.50" : "gray.400"}
            fontSize={{ base: "sm", md: "md", lg: "lg" }}
            transition={"all 0.2s"}
            textDecoration={currentIndex === index ? "underline" : "none"}
            textUnderlineOffset={currentIndex === index ? 14 : 20}
            textDecorationColor={"rivr.red"}
            textDecorationThickness={3}
            px={{ base: 3, md: 6, lg: 8 }}
            mb={3}
            _hover={{ color: "gray.50" }}
          >
            {text}
          </Text>
        ))}
      </Box>

      <motion.div
        key={currentIndex}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Text
          fontSize={{ base: "md", md: "md", lg: "lg" }}
          textAlign={"center"}
          pb={8}
          sx={{ textWrap: "pretty" }}
        >
          {items[currentIndex].description}
        </Text>
      </motion.div>

      <Box
        position={"relative"}
        width={"100%"}
        maxWidth={"1400px"}
        height={"auto"}
        overflow={"hidden"}
        borderRadius={"xl"}
        shadow={"0 25px 50px -12px rgba(228, 58, 25, 0.25)"}
        borderWidth={1}
        borderColor={"rivr.red"}
      >
        <Flex
          transition={"transform 0.75s ease-in-out"}
          transform={`translateX(-${currentIndex * 100}%)`}
        >
          {items.map(({ src, text }, index) => (
            <AspectRatio key={index} ratio={2260 / 1263} flex={"0 0 100%"}>
              <Image
                src={src}
                alt={text}
                objectFit={"cover"}
                borderWidth={1}
                borderColor={"rivr.red"}
              />
            </AspectRatio>
          ))}
        </Flex>
      </Box>
      <HStack align={"center"} justify={"center"} pt={10}>
        <Button
          rightIcon={<Send />}
          minW={"fit-content"}
          variant={"outline"}
          aria-label={"Book a demo"}
          as={"a"}
          href={"#contact"}
          backdropFilter={"blur(2px)"}
        >
          Contact
        </Button>
        <Button
          rightIcon={<Calendar />}
          minW={"fit-content"}
          bg={"rivr.red"}
          variant={"solid"}
          _hover={{ bg: "red.300" }}
          _active={{ bg: "red.300" }}
          aria-label={"Book a demo"}
          as={"a"}
          href={"https://meetings.hubspot.com/aidan-kessell"}
          target={"_blank"}
        >
          Book a demo
        </Button>
      </HStack>
    </VStack>
  );
};

export default B2BBenefits;
