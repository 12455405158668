import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layouts/layout";
import Home from "./pages/index";
import Navigation from "./components/Header/Navigation";
import Footer from "./components/HomePage/Footer";
import PrivacyPolicy from "./pages/privacypolicy";
import Careers from "./pages/careers";
import BetaRequest from "./pages/betarequest";
import EarlyAccessRequest from "./pages/earlyaccess";
import PageNotFound from "./pages/pagenotfound";
import BlogIndex from "./pages/Resources/Blog/index";
import "@fontsource/inter";
import { clarity } from "react-microsoft-clarity";
import blogRoutes from "./utils/blogRoutes";
import Roadmap from "./pages/roadmap";
import AmazonParticipation from "./pages/amazon-participation";
import B2B from "./pages/b2b";

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  colors: {
    rivr: {
      100: "#586A7D",
      200: "#3A4754",
      300: "#28323B",
      400: "#1D242B",
      500: "#14191E",
      600: "#0F1317",
      red: "#e43a19",
      black: "#0C0D14",
    },
  },
  fonts: {
    heading: `'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;`,
    body: `'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;`,
  },
});

function getMicrosoftClarityProjectId() {
  return process.env.REACT_APP_MICROSOFT_CLARITY_PROJECT_ID;
}
const clarityId = getMicrosoftClarityProjectId();
clarity.init(clarityId);

function App() {
  localStorage.setItem("chakra-ui-color-mode", "dark");
  return (
    <ChakraProvider theme={theme}>
      <Layout>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/brands" element={<B2B />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route
              path="/privacy-terms-of-service"
              element={<PrivacyPolicy />}
            />
            <Route path="/beta-request" element={<BetaRequest />} />
            <Route path="/early-access" element={<EarlyAccessRequest />} />
            <Route path="/blog" element={<BlogIndex />} />
            {blogRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
            <Route path="/amazon-creators" element={<AmazonParticipation />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </Router>
      </Layout>
    </ChakraProvider>
  );
}

export default App;
