import {
  Box,
  Heading,
  Text,
  Link,
  Divider,
  Center,
  List,
  ListItem,
} from "@chakra-ui/react";

const PrivacyPolicy = () => {
  return (
    <Box
      w={["100%"]}
      height={["100%"]}
      position={["relative"]}
      bg={"rivr.black"}
    >
      <Center>
        <Box maxW="1200px" py={48} px={6}>
          <Box textAlign={"right"}>
            <Link fontSize="sm" color={"rivr.red"} href="#terms">
              Skip to Terms of Service
            </Link>
          </Box>
          <Heading fontSize="5xl" as="h1" mb={3} id="privacy">
            Privacy Policy
          </Heading>
          <Text>
            At Rivr, accessible from{" "}
            <Link color={"rivr.red"} href="/">
              https://rivr.stream
            </Link>
            , one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is
            collected and recorded by Rivr and how we use it. If you have
            additional questions or require more information about our Privacy
            Policy, do not hesitate to{" "}
            <Link color={"rivr.red"} href="mailto:contact@rivr.stream">
              contact
            </Link>
            . This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Rivr. This policy is not
            applicable to any information collected offline or via channels
            other than this website. Consent By using our website, you hereby
            consent to our Privacy Policy and agree to its terms.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Information we collect
          </Heading>
          <Text>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information. If you
            contact us directly, we may receive additional information about you
            such as your name, email address, phone number, the contents of the
            message and/or attachments you may send us, and any other
            information you may choose to provide. When you register for an
            Account, we may ask for your contact information, including items
            such as name, company name, address, email address, and telephone
            number.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            How we use your information
          </Heading>
          <Text mt={3}>
            We use the information we collect in various ways, including to:
            <List mt={3}>
              <ListItem>- Provide, operate, and maintain our website</ListItem>
              <ListItem>
                - Improve, personalize, and expand our website
              </ListItem>
              <ListItem>
                - Understand and analyze how you use our website
              </ListItem>
              <ListItem>
                - Develop new products, services, features, and functionality
              </ListItem>
              <ListItem>
                - Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </ListItem>
              <ListItem>- Send you emails</ListItem>
              <ListItem>- Find and prevent fraud</ListItem>
            </List>
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Log Files
          </Heading>
          <Text>
            Rivr follows a standard procedure of using log files. These files
            log visitors when they visit websites. All hosting companies do this
            and a part of hosting services' analytics. The information collected
            by log files include internet protocol (IP) addresses, browser type,
            Internet Service Provider (ISP), date and time stamp, referring/exit
            pages, and possibly the number and screen location of clicks or
            taps. These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Cookies and Web Beacons
          </Heading>
          <Text>
            Like many other websites, Rivr uses 'cookies'. These cookies are
            used to store information including visitors' preferences, and the
            pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Web Technologies We Use
          </Heading>
          <List mt={3}>
            <ListItem>
              - <bold>Google Analytics:</bold> We use Google Analytics to
              collect standard internet log information and details of visitor
              behavior patterns. This tool allows us to better understand how
              visitors interact with our website, such as which pages are
              visited the most, how long visitors spend on each page, and which
              pages have high bounce rates. This helps us to improve the user
              experience and provide more relevant content.
            </ListItem>
            <ListItem>
              - <bold>Microsoft Clarity:</bold> We also use Microsoft Clarity to
              gain insights into how visitors interact with our website. This
              tool allows us to see heatmaps of where visitors click on our
              pages, as well as session recordings that show how visitors
              navigate through our website. This helps us to identify areas
              where we can improve the user experience and make our website more
              user-friendly.
            </ListItem>
            <ListItem>
              - <bold>Amplitude:</bold> We also use Amplitude to collect data on
              how visitors use our website and interact with our products.
              Amplitude allows us to analyze user behavior and identify trends
              in product usage. This helps us to improve our products and
              provide a better experience for our users.
            </ListItem>
            <Text mt={3}>
              It's important to note that these technologies use cookies to
              collect information about website usage. The information collected
              by these cookies is anonymous and does not personally identify
              individual visitors. The data collected by these tools may be
              transferred to and stored on servers located outside of your
              country or the country where you accessed our website. By using
              our website, you consent to the processing of data by these
              third-party services in accordance with their respective privacy
              policies.
            </Text>
            <Text mt={3}>
              Google Analytics:{" "}
              <Link
                color={"rivr.red"}
                href="https://policies.google.com/privacy"
                isExternal
              >
                https://policies.google.com/privacy
              </Link>
            </Text>
            <Text mt={3}>
              Microsoft Clarity:{" "}
              <Link
                color={"rivr.red"}
                href="https://privacy.microsoft.com/en-US/privacystatement"
                isExternal
              >
                https://privacy.microsoft.com/en-US/privacystatement
              </Link>
            </Text>
            <Text mt={3}>
              Amplitude:{" "}
              <Link
                color={"rivr.red"}
                href="https://amplitude.com/privacy"
                isExternal
              >
                https://amplitude.com/privacy
              </Link>
            </Text>
          </List>
          <Heading as="h2" mb={3} mt={12}>
            Third Party Privacy Policies
          </Heading>
          <Text>
            Rivr's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options. You can choose to disable cookies
            through your individual browser options. To know more detailed
            information about cookie management with specific web browsers, it
            can be found at the browsers' respective websites.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </Heading>
          <Text>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </Text>
          <List mt={3}>
            <ListItem>
              - Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </ListItem>
            <ListItem>
              - Request that a business delete any personal data about the
              consumer that a business has collected.
            </ListItem>
            <ListItem>
              - Request that a business that sells a consumer's personal data,
              not sell the consumer's personal data.
            </ListItem>
            <ListItem>
              - If you make a request, we have one month to respond to you.
            </ListItem>
            <ListItem>
              - If you would like to exercise any of these rights, please{" "}
              <Link color={"rivr.red"} href="mailto:privacy@rivr.stream">
                contact us
              </Link>
              .
            </ListItem>
          </List>
          <Heading as="h2" mb={3} mt={12}>
            GDPR Data Protection Rights
          </Heading>
          <Text>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </Text>
          <List mt={3}>
            <ListItem>
              - The right to access – You have the right to request copies of
              your personal data. We may charge you a small fee for this
              service.
            </ListItem>
            <ListItem>
              - The right to rectification – You have the right to request that
              we correct any information you believe is inaccurate. You also
              have the right to request that we complete the information you
              believe is incomplete.
            </ListItem>
            <ListItem>
              - The right to erasure – You have the right to request that we
              erase your personal data, under certain conditions.
            </ListItem>
            <ListItem>
              - The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </ListItem>
            <ListItem>
              - The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </ListItem>
            <ListItem>
              - The right to data portability – You have the right to request
              that we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </ListItem>
            <ListItem>
              - If you make a request, we have one month to respond to you.
            </ListItem>
            <ListItem>
              - If you would like to exercise any of these rights, please{" "}
              <Link color={"rivr.red"} href="mailto:gdpr@rivr.stream">
                contact us
              </Link>
              .
            </ListItem>
          </List>
          <Heading as="h2" mb={3} mt={12}>
            Children's Information
          </Heading>
          <Text>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity. Rivr
            does not knowingly collect any Personal Identifiable Information
            from children under the age of 13. If you think that your child
            provided this kind of information on our website, we strongly
            encourage you to contact us immediately and we will do our best
            efforts to promptly remove such information from our records.
          </Text>
          <Text mt={3}>
            <Link color={"rivr.red"} href="mailto:gdpr@rivr.stream">
              GDPR Contact
            </Link>
          </Text>
          <Text>
            <Link color={"rivr.red"} href="mailto:privacy@rivr.stream">
              Privacy Contact
            </Link>
          </Text>
          <Divider
            my={24}
            w="100%"
            borderWidth={4}
            borderColor={"rivr.red"}
            borderRadius={"full"}
            id="terms"
          />
          <Heading fontSize="5xl" as="h1" mb={3}>
            Terms and Conditions
          </Heading>
          <Text>
            Welcome to Rivr! These terms and conditions outline the rules and
            regulations for the use of Rivr & Botni.Vision, Inc.'s Websites,
            located at{" "}
            <Link color={"rivr.red"} href="/">
              https://rivr.stream
            </Link>{" "}
            and{" "}
            <Link color={"rivr.red"} href="https://botni.vision" isExternal>
              https://botni.vision
            </Link>{" "}
            respectively. By accessing this website we assume you accept these
            terms and conditions. Do not continue to use Rivr if you do not
            agree to take all of the terms and conditions stated on this page.
          </Text>
          <Text mt={3}>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company’s terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client’s needs in respect of
            provision of the Company’s stated services, in accordance with and
            subject to, prevailing law of Canada. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Cookies
          </Heading>
          <Text>
            We employ the use of cookies. By accessing Rivr, you agreed to use
            cookies in agreement with Rivr & Botni.Vision, Inc.'s Privacy
            Policy. Most interactive websites use cookies to let us retrieve the
            user’s details for each visit. Cookies are used by our website to
            enable the functionality of certain areas to make it easier for
            people visiting our website. Some of our affiliate/advertising
            partners may also use cookies.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            License
          </Heading>
          <Text>
            Unless otherwise stated, Botni.Vision, Inc. and/or its licensors own
            the intellectual property rights for all material on Rivr. All
            intellectual property rights are reserved. You may access this from
            Rivr for your own personal use subjected to restrictions set in
            these terms and conditions.
          </Text>
          <Text mt={3}>You must not:</Text>
          <List mt={3}>
            <ListItem>
              - Republish material from Rivr or Botni.Vision, Inc.
            </ListItem>
            <ListItem>
              - Sell, rent or sub-license material from Rivr or Botni.Vision,
              Inc.
            </ListItem>
            <ListItem>
              - Reproduce, duplicate or copy material from Rivr or Botni.Vision,
              Inc.
            </ListItem>
            <ListItem>
              - Redistribute content from Rivr or Botni.Vision, Inc.
            </ListItem>
          </List>
          <Heading as="h2" mb={3} mt={12}>
            Hyperlinking to our Content
          </Heading>
          <Text mt={3}>
            The following organizations may link to our Website without prior
            written approval:
          </Text>
          <Text mt={3}>
            Government agencies; Search engines; News organizations; Online
            directory distributors may link to our Website in the same manner as
            they hyperlink to the Websites of other listed businesses; and
            System wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Web site. These organizations
            may link to our home page, to publications or to other Website
            information so long as the link: (a) is not in any way deceptive;
            (b) does not falsely imply sponsorship, endorsement or approval of
            the linking party and its products and/or services; and (c) fits
            within the context of the linking party’s site. We may consider and
            approve other link requests from the following types of
            organizations: commonly-known consumer and/or business information
            sources; dot.com community sites; associations or other groups
            representing charities; online directory distributors; internet
            portals; accounting, law and consulting firms; and educational
            institutions and trade associations.
          </Text>
          <Text mt={3}>
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of Botni.Vision,
            Inc.; and (d) the link is in the context of general resource
            information.
          </Text>
          <Text mt={3}>
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party’s site.
          </Text>
          <Text mt={3}>
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to{" "}
            <Link color={"rivr.red"} href="mailto:contact@botni.vision">
              Botni.Vision, Inc.
            </Link>{" "}
            Please include your name, your organization name, contact
            information as well as the URL of your site, a list of any URLs from
            which you intend to link to our Website, and a list of the URLs on
            our site to which you would like to link. Wait 2-3 weeks for a
            response.
          </Text>
          <Text mt={3}>
            Approved organizations may hyperlink to our Website as follows: By
            use of our corporate name; or By use of the uniform resource locator
            being linked to; or By use of any other description of our Website
            being linked to that makes sense within the context and format of
            content on the linking party’s site. No use of Botni.Vision, Inc.'s
            or Rivr's logo or other artwork will be allowed for linking absent a
            trademark license agreement.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Content Liability
          </Heading>
          <Text
            textAlign="center"
            fontWeight="bold"
            bg="rivr.red"
            p={6}
            borderRadius={8}
            mb={3}
          >
            By using Rivr you agree that we shall not be held responsible for
            any content that is downloaded, created, generated, modified, or
            distributed through, from, or with Rivr. We shall not be held
            responsible for content that appears on yours or any other Website.
          </Text>
          <Text>
            You agree to protect and defend us against all claims that is rising
            on your Website. No link(s) should appear on any Website that may be
            interpreted as libelous, obscene or criminal, or which infringes,
            otherwise violates, or advocates the infringement or other violation
            of, any third party rights.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Your Privacy
          </Heading>
          <Text>
            Please read our{" "}
            <Link color={"rivr.red"} href="/privacy-terms-of-service">
              Privacy Policy.
            </Link>{" "}
            (This page)
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Reservation of Rights
          </Heading>
          <Text>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it’s linking policies at any
            time. By continuously linking to our Website, you agree to be bound
            to and follow these linking terms and conditions.
          </Text>
          <Heading as="h3" mb={3} mt={12}>
            Removal of links from our website
          </Heading>
          <Text>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly. We do not ensure that the information
            on this website is correct, we do not warrant its completeness or
            accuracy; nor do we promise to ensure that the website remains
            available or that the material on the website is kept up to date.
          </Text>
          <Heading as="h2" mb={3} mt={12}>
            Disclaimer
          </Heading>
          <Text>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will: limit
            or exclude our or your liability for death or personal injury; limit
            or exclude our or your liability for fraud or fraudulent
            misrepresentation; limit any of our or your liabilities in any way
            that is not permitted under applicable law; or exclude any of our or
            your liabilities that may not be excluded under applicable law. The
            limitations and prohibitions of liability set in this Section and
            elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty. As long as the website and the
            information and services on the website are provided free of charge,
            we will not be liable for any loss or damage of any nature.
          </Text>
        </Box>
      </Center>
    </Box>
  );
};

export default PrivacyPolicy;
