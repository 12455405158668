import {
  Box,
  Flex,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Link,
  UnorderedList,
  ListItem,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import { ArrowRight } from "@icon-park/react";

const Faq = () => {
  const faqItems = [
    {
      question: "What is Rivr?",
      answer:
        "Rivr is a revolutionary platform for searching within media, discovering your best Moments, and turning them into content your audience will love.",
    },
    {
      question: "How does Rivr work?",
      answer: (
        <>
          <VStack alignItems={"flex-start"} spacing={4}>
            <Text>
              Rivr uses advanced technology to translate media into data. That
              data helps you quickly identify interesting Moments and learn what
              your audience finds most engaging.
            </Text>
            <Text>
              Once you’ve found great stuff, Rivr helps you optimize your
              content production workflow and make the most of every Moment.
              With built-in reformatting tools and integrations, you can stop
              spending hours scrubbing and start sharing amazing content – fast.
            </Text>
          </VStack>
        </>
      ),
    },
    {
      question: "What are the benefits of using Rivr?",
      answer: (
        <>
          <UnorderedList pb={1} pt={1} ml={-1} listStyleType={"none"}>
            <ListItem>
              ⏰ Spend less time scrubbing and editing. Do more of what you
              love.
            </ListItem>
            <ListItem>
              ⭐ Let Signal data highlight your highlights so you never miss a
              Moment.
            </ListItem>
            <ListItem>
              🎯 Gain actionable audience insights and create better content.
            </ListItem>
            <ListItem>
              💪 Put your content to work across many channels and grow your
              followers.
            </ListItem>
            <ListItem>
              🤑 Save money with an affordable alternative to professional
              editing.
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
    {
      question: "What platforms does Rivr work with?",
      answer: (
        <>
          <Text>
            For closed-beta users, Rivr currently supports media hosted on
            Twitch and YouTube. If you have media hosted elsewhere and want to
            learn how Rivr can help your business,{" "}
            <Link
              isExternal
              href="mailto:sales@rivr.stream"
              textDecoration={"underline"}
              _hover={{ color: "gray.50" }}
            >
              get in touch
            </Link>
            . Rivr can reformat content to be suitable for TikTok, YouTube
            Shorts, and Instagram Reels.
          </Text>
        </>
      ),
    },
    {
      question: "What type of content does Rivr work best on?",
      answer:
        "Rivr can effectively analyze many types of content – gaming, podcasts, live events, workshops, interviews, and more. Media with live-chat channels works best, allowing you to discover standout Moments based on community response and engagement.",
    },
    {
      question: "For gaming, what does Rivr support?",
      answer: (
        <>
          <Text>
            Rivr is the <strong>only</strong> platform that supports all games,
            from retro to next-gen, cozy to speedrunning. Play what you want to
            play, or don’t play a game at all – either way, Rivr will help you
            discover the best Moments in your media.
          </Text>
        </>
      ),
    },

    {
      question: "Is Rivr AI?",
      answer: (
        <>
          <VStack alignItems={"flex-start"} spacing={4}>
            <Text>
              Yes, where it makes sense, and no where it doesn't. AI is a tool,
              not the entire solution, and Rivr isn't an AI autoclipper.
            </Text>
            <Text>
              Rather than telling you what constitutes good or bad content, or
              limiting you to a specific set of predetermined Moments, Rivr
              provides meaningful data to help you make quick and informed
              decisions for yourself.
            </Text>
          </VStack>
        </>
      ),
    },
    {
      question: "Does Rivr collect data about my likeness or personality?",
      answer: (
        <>
          <Text>
            Nope! We understand the importance of creators’{" "}
            <Link
              isExternal
              href="https://wikipedia.org/wiki/Personality_rights"
              textDecoration={"underline"}
              _hover={{ color: "gray.50" }}
            >
              personality rights
            </Link>{" "}
            and follow all applicable laws relating to data privacy and
            protection. Any use of user data for machine learning or AI, in any
            capacity, is strictly opt-in.
          </Text>
        </>
      ),
    },
    {
      question: "Does Rivr have an affiliate program?",
      answer:
        "Rivr does not have an affiliate or partner program at this time. Following a wider release, we intend to create a program that offers benefits for users who share Rivr with their communities.",
    },
    {
      question: "I’m a game developer or publisher. How can I use Rivr?",
      answer: (
        <>
          <Text>
            Rivr has many proven use cases throughout a game’s product
            lifecycle.{" "}
            <Link
              isExternal
              href="mailto:sales@rivr.stream"
              textDecoration={"underline"}
              _hover={{ color: "gray.50" }}
            >
              Get in touch
            </Link>{" "}
            with our Sales team to learn how Rivr can level up your launch
            campaigns, activations, creator programs, and more.
          </Text>
          <Button
            mt={6}
            colorScheme="green"
            as="a"
            href="/brands"
            rightIcon={<ArrowRight />}
          >
            Learn more
          </Button>
        </>
      ),
    },
    {
      question:
        "We’re a brand, agency, or creator organization. How can we use Rivr?",
      answer: (
        <>
          <Text>
            Agencies and creator organizations can use Rivr at scale to execute
            efficient, successful campaigns.{" "}
            <Link
              isExternal
              href="mailto:sales@rivr.stream"
              textDecoration={"underline"}
              _hover={{ color: "gray.50" }}
            >
              Get in touch
            </Link>{" "}
            with our Sales team for details.
          </Text>
          <Button
            mt={6}
            colorScheme="green"
            as="a"
            href="/brands"
            rightIcon={<ArrowRight />}
          >
            Learn more
          </Button>
        </>
      ),
    },
    {
      question: "Rivr isn’t working. Where can I get help?",
      answer: (
        <>
          <VStack spacing={4} alignItems={"flex-start"}>
            <Text>Rivr has several support channels:</Text>
            <UnorderedList pl={8} pb={2}>
              <ListItem>
                <strong>Live chat support</strong> – available within the{" "}
                <Link
                  isExternal
                  href="https://app.rivr.stream"
                  textDecoration={"underline"}
                  _hover={{ color: "gray.50" }}
                >
                  Rivr platform
                </Link>
                .
              </ListItem>
              <ListItem>
                <Link
                  isExternal
                  href="https://discord.gg/WTkWJKzyQK"
                  textDecoration={"underline"}
                  _hover={{ color: "gray.50" }}
                >
                  <strong>Discord</strong>
                </Link>{" "}
                – Create private support tickets in the #technical-support
                channel
              </ListItem>
              <ListItem>
                <strong>Email</strong> – Send a message to{" "}
                <Link
                  isExternal
                  href="mailto:support@rivr.stream"
                  textDecoration={"underline"}
                  _hover={{ color: "gray.50" }}
                >
                  support@rivr.stream
                </Link>
              </ListItem>
              <ListItem>
                <strong>Twitter/X</strong> – Send a DM to{" "}
                <Link
                  isExternal
                  href="https://x.com/rivrstream"
                  textDecoration={"underline"}
                  _hover={{ color: "gray.50" }}
                >
                  @rivrstream
                </Link>
              </ListItem>
            </UnorderedList>
            <HStack>
              <Button
                colorScheme="purple"
                as="a"
                href="https://discord.gg/WTkWJKzyQK"
              >
                Join Discord
              </Button>
              <Button colorScheme="blue" as="a" href="https://x.com/rivrstream">
                Follow on X/Twitter
              </Button>
            </HStack>
          </VStack>
        </>
      ),
    },
  ];

  return (
    <Box position={"relative"} w={"100%"} overflow={"hidden"}>
      <Flex
        w="100%"
        maxW={"1400px"}
        px={"6"}
        py={[32, 32, 64]}
        h="100%"
        mx="auto"
      >
        <VStack spacing={12} w={"100%"}>
          <VStack justifyContent={"center"} w={"100%"} spacing={5}>
            <div
              id="faq"
              style={{
                position: "relative",
                top: "-104px",
                visibility: "hidden",
              }}
            ></div>
            <Heading
              as={"h2"}
              size={"lg"}
              color={"gray.50"}
              textAlign={"center"}
            >
              Frequently Asked Questions
            </Heading>
          </VStack>
          <Accordion allowToggle w={"100%"}>
            {faqItems.map((item, index) => (
              <AccordionItem key={index} py={4}>
                <AccordionButton
                  _hover={{ bg: "whiteAlpha.200" }}
                  _expanded={{ bg: "rivr.red", color: "white" }}
                  borderRadius={"md"}
                >
                  <Box as={"span"} flex={"1"} textAlign={"left"}>
                    <Text
                      fontSize={{ xl: "xl", lg: "xl", md: "md", sm: "md" }}
                      fontWeight={"bold"}
                    >
                      {item.question}
                    </Text>
                  </Box>
                  <AccordionIcon boxSize={8} />
                </AccordionButton>
                <AccordionPanel mt={3} pb={2} color="gray.300">
                  {item.answer}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </VStack>
      </Flex>
    </Box>
  );
};

export default Faq;
