import { Box, Button, Flex, Heading, Link, VStack } from "@chakra-ui/react";
import videoRivrUi from "../../assets/video/rivr-ui.webm";
import videoRivrUiMp4 from "../../assets/video/rivr-ui.mp4";
import heroVideoThumbnail from "../../assets/video/hero-video-thumbnail.webp";
import "./HeroSection.css";
import { ChevronRightIcon } from "@chakra-ui/icons";

const HeroSection = () => {
  return (
    <Box bg={"rivr.black"}>
      <Box position="relative" w="100%" overflow="hidden">
        <VStack
          w="100%"
          maxW={"100%"}
          px={"6"}
          py={[32, 32, 64]}
          h="100%"
          justifyContent="center"
          mx="auto"
          zIndex={1}
          spacing={8}
        >
          <Heading
            as={"h1"}
            size={["xl", "2xl", "4xl"]}
            color={"gray.50"}
            textAlign={"center"}
            zIndex={100}
            sx={{ textWrap: "balance" }}
            lineHeight={"1.2 !important"}
          >
            <span
              style={{
                color: "#e43a19",
                fontStyle: "italic",
                marginRight: "1.3%",
              }}
            >
              Rivr
            </span>
            lutionizing Content Creation
          </Heading>
          <Heading
            as="h2"
            size={{ xl: "md", lg: "md", md: "md", sm: "sm" }}
            color="gray.300"
            fontWeight={"medium"}
            mt={8}
            textAlign="center"
            lineHeight="1.5"
            zIndex={100}
            sx={{ textWrap: "pretty" }}
          >
            Turn video into searchable experiences.
            <br />
            Discover and share your best Moments.
          </Heading>
          <Link href="/early-access">
            <Button
              rightIcon={<ChevronRightIcon />}
              aria-label="Join early access"
              colorScheme={"red"}
              bg={"rivr.red"}
              color={"gray.50"}
            >
              Join early access
            </Button>
          </Link>
          <Flex maxWidth="1400px" pt={[2, 2, 4]} borderRadius={"xl"}>
            <div className="videoWrapper">
              <div className="ff-videoWrapper">
                <video
                  className="videohere"
                  style={{
                    borderRadius: "0.75rem",
                    borderWidth: "1px",
                    borderColor: "#181924",
                    zIndex: 3,
                  }}
                  autoPlay
                  muted
                  loop
                  poster={heroVideoThumbnail}
                >
                  <source src={videoRivrUi} type="video/webm" />
                  <source src={videoRivrUiMp4} type="video/mp4" />
                </video>
              </div>
            </div>
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
};

export default HeroSection;
